<template>               
<div class="slide-container">
    <!--検索結果カードデータ-->
    <div class="result_data" id="category_01" :style="{paddingTop: stylePaddingTop}">
        <div class="category_title">
            <h2 class="title_guide">計画・方針</h2>
            <span class="faq_icon">
                <span class="icon"></span>
                <span class="comment">以下の資料が表示されます。<br>・基本構想<br>・基本計画<br>・実施計画<br>・分野別計画<br>・施政方針<br>・重点施策<br>・総合戦略<br>・人口ビジョン</span>
            </span>
            <span class="display_number">
                <strong>（</strong>
                <strong v-if="currentGroupkeikakuhoshin">{{ currentGroupkeikakuhoshin.start }} - {{ currentGroupkeikakuhoshin.end }}</strong>
                <strong v-if="getKeikakuhoshin && getKeikakuhoshin.length === 0">0</strong>
                件 / 全{{getKeikakuhoshin.length}}件を表示 )
            </span>
        </div>
        <div class="result_card_area">
            <div class="cards">
                <div class="card_item_track" :style="{left: keikakuhoshinCurrentLeft}">
                    <div v-if="getKeikakuhoshin && getKeikakuhoshin.length === 0" style="padding-left: 20px; color:#808080;">検索に一致する情報は見つかりませんでした。</div>
                    <div v-for="(group, groupIndex) in groupedKeikakuhoshin" :key="groupIndex" class="group_card_item">
                    <!--カード1-->
                    <!-- <div v-for="keikakuhoshin in getKeikakuhoshin" :key="keikakuhoshin" class="card_item"> -->
                        <div v-for="(keikakuhoshin, index) in group" :key="index" class="card_item">
                            <p>
                                <span class="area">{{ findAffiliation(keikakuhoshin.affiliation_code) }} {{ findOrganization(keikakuhoshin.code) }}</span>
                                <br>
                                <span class="format">{{ subCategoryReformat(keikakuhoshin.sub_category) }}</span>
                                <span class="year">{{ getFiscalYear(keikakuhoshin) }}</span>
                            </p>
                            <div class="flex_bw_c">
                                <a v-bind:href="keikakuhoshin.category === 3 ? getSourceUrl(keikakuhoshin) : getOwnedFileUrl(keikakuhoshin)" @click="select_content(keikakuhoshin, index, groupIndex)" target="_blank">
                                    <h3>{{limitString(keikakuhoshin.title, 68)}}</h3>
                                </a>
                                <!-- <button class="favorite"></button> -->
                            </div>
                            <div class="document_data" :style="{ height: shouldIncreaseHeight(keikakuhoshin.title) ? '13.1em' : '11.2em' }">
                                <div class="document_image">
                                    <a v-bind:href="keikakuhoshin.category === 3 ? getSourceUrl(keikakuhoshin) : getOwnedFileUrl(keikakuhoshin)" @click="select_content(keikakuhoshin, index, groupIndex)" target="_blank">
                                        <img :src="getImageUrl(keikakuhoshin)" class="document_image" onerror="this.style.display='none';" />
                                        <!--複数ページ-->
                                        <span class="document_page multiple">{{keikakuhoshin.file_page}}/{{keikakuhoshin.number_of_pages}}</span>
                                        <!--単ページ-->
                                        <!--<span class="document_page single">1/1</span>-->
                                    </a>
                                </div>
                                <div class="text" v-html="getContentText(keikakuhoshin)"></div>
                            </div>
                            <div class="document_tag">
                                <span v-for=" tags in keikakuhoshin.tags" :key="tags" >{{tags}}</span>
                            </div>
                        </div>
                    <!--/カード1-->
                    </div>
                </div>
            </div>
            <button v-if="getKeikakuhoshin && getKeikakuhoshin.length > 0" @click="keikakuhoshinBackwardPage" class="result_prev"></button>
            <button v-if="getKeikakuhoshin && getKeikakuhoshin.length > 0" @click="keikakuhoshinForwardPage" class="result_next"></button>
        </div>
        <div class="page_navigation flex_c">
            <!-- <button class="prev disabled"></button> -->
            <button v-if="getKeikakuhoshin && getKeikakuhoshin.length > 0" @click="keikakuhoshinBackwardPage" class="prev" :class="{ 'disabled': keikakuhoshinCurrentpage === 1 }"></button>
            <!-- <span class="current"><input v-model="keikakuhoshinCurrentpage" type="number" @input="keikakuhoshinHandleInput"> </span> -->
            <!-- <span class="text"> / {{keikakuhoshinTotalpage}} ページ</span> -->
            <div v-if="currentGroupkeikakuhoshin">
                <span class="text">{{ currentGroupkeikakuhoshin.start }} ~ {{ currentGroupkeikakuhoshin.end }}</span>
            </div>
            <button v-if="getKeikakuhoshin && getKeikakuhoshin.length > 0" @click="keikakuhoshinForwardPage" class="next" :class="{ 'disabled': keikakuhoshinCurrentpage === keikakuhoshinGroups.length }"></button>
        </div>
        <!-- <div class="more">
            <button class="btn_more">もっと見る</button>
            <p>（ <span>1 - 12</span> 件 / 全120件を表示 )</p>
        </div> -->
    </div>    
    <!--/検索結果カードデータ-->
    <!--検索結果カードデータ-->
    <div class="result_data" id="category_02" :style="{paddingTop: stylePaddingTop}">
        <div class="category_title">
            <h2 class="title_guide">予算</h2>
            <span class="faq_icon">
                <span class="icon"></span>
                <span class="comment">以下の資料が表示されます。<br>・当初予算<br>・補正予算</span>
            </span>
            <span class="display_number">
            <strong>（</strong>            
                <strong v-if="currentGroupYosan">{{ currentGroupYosan.start }} - {{ currentGroupYosan.end }}</strong>
                <strong v-if="getYosan && getYosan.length === 0">0</strong>
                件 / 全{{getYosan.length}}件を表示 )
            </span>
        </div>
        <div class="result_card_area">
            <div class="cards">
                <div class="card_item_track" :style="{left: yosanCurrentLeft}">
                    <div v-if="getYosan && getYosan.length === 0" style="padding-left: 20px; color:#808080;">検索に一致する情報は見つかりませんでした。</div>
                    <div v-for="(group, groupIndex) in groupedYosan" :key="groupIndex" class="group_card_item">
                    <!--カード1-->
                        <div v-for="(yosan, index) in group" :key="index" class="card_item">
                            <p>
                                <span class="area">{{findAffiliation(yosan.affiliation_code)}} {{findOrganization(yosan.code)}}</span>
                                <br>
                                <span class="format">{{ subCategoryReformat(yosan.sub_category) }}</span>
                                <span class="year">{{ getFiscalYear(yosan) }}</span>
                            </p>
                            <div class="flex_bw_c">
                            <a v-bind:href="getOwnedFileUrl(yosan)" @click="select_content(yosan, index, groupIndex)" target="_blank">
                                <h3>{{limitString(yosan.title, 68)}}</h3>
                            </a>
                            <!-- <button class="favorite"></button> -->
                        </div>
                        <div class="document_data" :style="{ height: shouldIncreaseHeight(yosan.title) ? '13.1em' : '11.2em' }">
                            <div class="document_image">
                                <a v-bind:href="getOwnedFileUrl(yosan)" @click="select_content(yosan, index, groupIndex)" target="_blank">
                                    <img :src="getImageUrl(yosan)" class="document_image">
                                    <!--複数ページ-->
                                    <span class="document_page multiple">{{yosan.file_page}}/{{yosan.number_of_pages}}</span>
                                    <!--単ページ-->
                                </a>
                            </div>
                            <div class="text" v-html="getContentText(yosan)"></div>
                        </div>
                        <div class="document_tag">
                            <span v-for=" tags in yosan.tags" :key="tags" >{{tags}}</span>
                        </div>
                    </div>
                    <!--/カード1-->
                    </div>
                </div>
            </div>
            <button v-if="getYosan && getYosan.length > 0" @click="yosanBackwardPage" class="result_prev"></button>
            <button v-if="getYosan && getYosan.length > 0" @click="yosanForwardPage" class="result_next"></button>
        </div>
        <div class="page_navigation flex_c">
            <!-- <button class="prev disabled"></button> -->
            <button v-if="getYosan && getYosan.length > 0" @click="yosanBackwardPage" class="prev" :class="{ 'disabled': yosanCurrentpage === 1 }"></button>
            <!-- <span class="current"><input v-model="yosanCurrentpage" type="number" @input="yosanHandleInput"></span>
            <span class="text"> / {{yosanTotalpage}} ページ</span> -->
            <div v-if="currentGroupYosan">
                <span class="text">{{ currentGroupYosan.start }} ~ {{ currentGroupYosan.end }}</span>
            </div>
            <button v-if="getYosan && getYosan.length > 0" @click="yosanForwardPage" class="next" :class="{ 'disabled': yosanCurrentpage === yosanGroups.length }"></button>
        </div>
    </div>        
    <!--/検索結果カードデータ-->
    <!--検索結果カードデータ-->
    <div class="result_data" id="category_03" :style="{paddingTop: stylePaddingTop}">
        <div class="category_title">
            <h2 class="title_guide">広報・議会だより</h2>
            <span class="faq_icon">
                <span class="icon"></span>
                <span class="comment">以下の資料が表示されます。<br>・広報紙<br>・議会だより<br>・記者発表<br>・記者会見</span>
            </span>
            <span class="display_number">
            <strong>（</strong>
                <strong v-if="currentGroupKouhou">{{ currentGroupKouhou.start }} - {{ currentGroupKouhou.end }}</strong>
                <strong v-if="getKouhou && getKouhou.length === 0">0</strong>
                件 / 全{{getKouhou.length}}件を表示 )
            </span>        
        </div>
        <div class="result_card_area">
            <div class="cards">
                <div class="card_item_track" :style="{left: kouhouCurrentLeft}">
                    <div v-if="getKouhou && getKouhou.length === 0" style="padding-left: 20px; color:#808080;">検索に一致する情報は見つかりませんでした。</div>
                    <div v-for="(group, groupIndex) in groupedKouhou" :key="groupIndex" class="group_card_item">
                    <!--カード1-->
                        <div v-for="(kouhou, index) in group" :key="index" class="card_item">
                            <p>
                                <span class="area">{{findAffiliation(kouhou.affiliation_code)}} {{findOrganization(kouhou.code)}}</span>
                                <br>
                                <span class="format">{{ subCategoryReformat(kouhou.sub_category) }}</span>
                                <span class="year">{{ getFiscalYear(kouhou) }}</span>
                            </p>
                            <div class="flex_bw_c">
                            <a v-bind:href="getOwnedFileUrl(kouhou)" @click="select_content(kouhou, index, groupIndex)" target="_blank">
                                <h3>{{limitString(kouhou.title, 68)}}</h3>
                            </a>
                            <!-- <button class="favorite"></button> -->
                        </div>
                        <div class="document_data" :style="{ height: shouldIncreaseHeight(kouhou.title) ? '13.1em' : '11.2em' }">
                            <div class="document_image">
                                <a v-bind:href="getOwnedFileUrl(kouhou)" @click="select_content(kouhou, index, groupIndex)" target="_blank">
                                    <img :src="getImageUrl(kouhou)" class="document_image">
                                    <!--複数ページ-->
                                    <span class="document_page multiple">{{kouhou.file_page}}/{{kouhou.number_of_pages}}</span>
                                    <!--単ページ-->
                                </a>
                            </div>
                            <div class="text" v-html="getContentText(kouhou)"></div>
                        </div>
                        <div class="document_tag">
                            <span v-for=" tags in kouhou.tags" :key="tags" >{{tags}}</span>
                        </div>
                    </div>
                    <!--/カード1-->
                    </div>
                </div>
            </div>
            <button v-if="getKouhou && getKouhou.length > 0" @click="kouhouBackwardPage" class="result_prev"></button>
            <button v-if="getKouhou && getKouhou.length > 0" @click="kouhouForwardPage" class="result_next"></button>
        </div>
        <div class="page_navigation flex_c">
            <button v-if="getKouhou && getKouhou.length > 0" @click="kouhouBackwardPage" class="prev" :class="{ 'disabled': kouhouCurrentpage === 1 }"></button>
            <!-- <span class="current"><input v-model="kouhouCurrentpage" type="number" @input="kouhouHandleInput"></span>
            <span class="text"> / {{kouhouTotalpage}} ページ</span> -->
            <div v-if="currentGroupKouhou">
                <span class="text">{{ currentGroupKouhou.start }} ~ {{ currentGroupKouhou.end }}</span>
            </div>
            <button v-if="getKouhou && getKouhou.length > 0" @click="kouhouForwardPage" class="next" :class="{ 'disabled': kouhouCurrentpage === kouhouGroups.length }"></button>
        </div>
    </div>
    <!--/検索結果カードデータ-->
    <!--検索結果カードデータ-->
    <div class="result_data" id="category_04" :style="{paddingTop: stylePaddingTop}">
        <div class="category_title">
            <h2 class="title_guide">議会議事録</h2>
            <span class="faq_icon">
                <span class="icon"></span>
                <span class="comment">以下の資料が表示されます。<br>・議会議事録<br>・委員会議事録</span>
            </span>
            <span class="display_number">
            <strong>（</strong>            
                <strong v-if="currentGroupGikaigijiroku">{{ currentGroupGikaigijiroku.start }} - {{ currentGroupGikaigijiroku.end }}</strong>
                <strong v-if="getGikaigijiroku && getGikaigijiroku.length === 0">0</strong>
                件 / 全{{getGikaigijiroku.length}}件を表示 )
            </span>  
        </div>
        <div class="result_card_area">
            <div class="cards">
                <div class="card_item_track" :style="{left: gikaigijirokuCurrentLeft}">
                    <div v-if="getGikaigijiroku && getGikaigijiroku.length === 0" style="padding-left: 20px; color:#808080;">検索に一致する情報は見つかりませんでした。</div>
                    <div v-for="(group, groupIndex) in groupedGikaigijiroku" :key="groupIndex" class="group_card_item">
                    <!--カード1-->
                        <div v-for="(gikaigijiroku, index) in group" :key="index" class="card_item">
                            <p>
                                <span class="area">{{findAffiliation(gikaigijiroku.affiliation_code)}} {{findOrganization(gikaigijiroku.code)}}</span>
                                <br>
                                <span class="format">{{ subCategoryReformat(gikaigijiroku.sub_category) }}</span>
                                <span class="year">{{ getFiscalYear(gikaigijiroku) }}</span>
                            </p>
                            <div class="flex_bw_c">
                            <a v-bind:href="getSourceUrl(gikaigijiroku)" @click="select_content(gikaigijiroku, index, groupIndex)" target="_blank">
                                <h3>{{limitString(gikaigijiroku.title, 68)}}</h3>                                
                            </a>
                            <!-- <button class="favorite"></button> -->
                        </div>
                        <div class="document_data" :style="{ height: shouldIncreaseHeight(gikaigijiroku.title) ? '13.1em' : '11.2em' }">
                            <div class="document_image_minutes">
                                <a v-bind:href="getSourceUrl(gikaigijiroku)" @click="select_content(gikaigijiroku, index, groupIndex)" target="_blank">
                                    <!--複数ページ-->
                                    <span class="plural">{{gikaigijiroku.file_page}}/{{gikaigijiroku.number_of_pages}}</span>
                                    <!--単ページ-->
                                </a>
                            </div>
                            <div class="text" v-html="getContentText(gikaigijiroku)"></div>
                        </div>
                        <div class="document_tag">
                            <span v-for=" tags in gikaigijiroku.tags" :key="tags" >{{tags}}</span>
                        </div>
                    </div>
                    <!--/カード1-->
                    </div>
                </div>
            </div>
            <button v-if="getGikaigijiroku && getGikaigijiroku.length > 0" @click="gikaigijirokuBackwardPage" class="result_prev"></button>
            <button v-if="getGikaigijiroku && getGikaigijiroku.length > 0" @click="gikaigijirokuForwardPage" class="result_next"></button>
        </div>
        <div class="page_navigation flex_c">
            <button v-if="getGikaigijiroku && getGikaigijiroku.length > 0" @click="gikaigijirokuBackwardPage" class="prev" :class="{ 'disabled': gikaigijirokuCurrentpage === 1 }"></button>
            <!-- <span class="current"><input v-model="gikaigijirokuCurrentpage" type="number" @input="gikaigijirokuHandleInput"></span>
            <span class="text"> / {{gikaigijirokuTotalpage}} ページ</span> -->
            <div v-if="currentGroupGikaigijiroku">
                <span class="text">{{ currentGroupGikaigijiroku.start }} ~ {{ currentGroupGikaigijiroku.end }}</span>
            </div>
            <button v-if="getGikaigijiroku && getGikaigijiroku.length > 0" @click="gikaigijirokuForwardPage" class="next" :class="{ 'disabled': gikaigijirokuCurrentpage === gikaigijirokuGroups.length }"></button>
        </div>
    </div>
    <!--/検索結果カードデータ-->
    <!--検索結果カードデータ-->
    <div class="result_data" id="category_05" :style="{paddingTop: stylePaddingTop}">
        <div class="category_title">
            <h2 class="title_guide">その他</h2>
            <span class="faq_icon">
                <span class="icon"></span>
                <span class="comment">計画・方針、予算、広報、議会議事録に分類されない、その他の資料が表示されます。</span>
            </span>
            <span class="display_number">
            <strong>（</strong>            
                <strong v-if="currentGroupSonota">{{ currentGroupSonota.start }} - {{ currentGroupSonota.end }}</strong>
                <strong v-if="getSonota && getSonota.length === 0">0</strong>
                件 / 全{{getSonota.length}}件を表示 )
            </span>  
        </div>
        <div class="result_card_area">
            <div class="cards">
                <div class="card_item_track" :style="{left: sonotaCurrentLeft}">
                    <div v-if="getSonota && getSonota.length === 0" style="padding-left: 20px; color:#808080;">検索に一致する情報は見つかりませんでした。</div>
                    <div v-for="(group, groupIndex) in groupedSonota" :key="groupIndex" class="group_card_item">
                    <!--カード1-->
                        <div v-for="(sonota, index) in group" :key="index" class="card_item">
                            <p>
                                <span class="area">{{findAffiliation(sonota.affiliation_code)}} {{findOrganization(sonota.code)}}</span>
                                <br>
                                <span class="format">{{ subCategoryReformat(sonota.sub_category) }}</span>
                                <span class="year">{{ getFiscalYear(sonota) }}</span>
                            </p>
                            <div class="flex_bw_c">
                            <a v-bind:href="getOwnedFileUrl(sonota)" @click="select_content(sonota, index, groupIndex)" target="_blank">
                                <h3>{{limitString(sonota.title, 68)}}</h3>
                            </a>
                            <!-- <button class="favorite"></button> -->
                        </div>
                        <div class="document_data" :style="{ height: shouldIncreaseHeight(sonota.title) ? '13.1em' : '11.2em' }">
                            <div class="document_image">
                                <a v-bind:href="getOwnedFileUrl(sonota)" @click="select_content(sonota, index, groupIndex)" target="_blank">
                                    <img :src="getImageUrl(sonota)" class="document_image">
                                    <!--複数ページ-->
                                    <span class="document_page multiple">{{sonota.file_page}}/{{sonota.number_of_pages}}</span>
                                    <!--単ページ-->
                                </a>
                            </div>
                            <div class="text" v-html="getContentText(sonota)"></div>
                        </div>
                        <div class="document_tag">
                            <span v-for=" tags in sonota.tags" :key="tags" >{{tags}}</span>
                        </div>
                    </div>
                    <!--/カード1-->
                    </div>
                </div>
            </div>
            <button v-if="getSonota && getSonota.length > 0" @click="sonotaBackwardPage" class="result_prev"></button>
            <button v-if="getSonota && getSonota.length > 0" @click="sonotaForwardPage" class="result_next"></button>
        </div>
        <div class="page_navigation flex_c">
            <button v-if="getSonota && getSonota.length > 0" @click="sonotaBackwardPage" class="prev" :class="{ 'disabled': sonotaCurrentpage === 1 }"></button>
            <!-- <span class="current"><input v-model="sonotaCurrentpage" type="number" @input="sonotaHandleInput"></span>
            <span class="text"> / {{sonotaTotalpage}} ページ</span> -->
            <div v-if="currentGroupSonota">
                <span class="text">{{ currentGroupSonota.start }} ~ {{ currentGroupSonota.end }}</span>
            </div>
            <button v-if="getSonota && getSonota.length > 0" @click="sonotaForwardPage" class="next" :class="{ 'disabled': sonotaCurrentpage === sonotaGroups.length }"></button>
        </div>
    </div>
		<!--/検索結果カードデータ-->
</div>

</template>

<script>
import affiliationsData from "@/assets/data/affiliations.json"; 
import organizationsData from "@/assets/data/organizations.json";
import document_data_format from "@/assets/data/document_data_format.json";
import { LogEventSelectContent } from "@/analytics";

export default {
    name: 'DocumentSection',
    data(){
        return{
            // keikakuhoshin : [],
            // jisshi : [],
            // bunyabetsu : [],
            // yosan : [],
            // sonota : [],
            model: 0, 
            keikakuhoshinCurrentpage: 1, // 現在のページ
            keikakuhoshinTotalpage: 0,   // ページの全数
            yosanCurrentpage: 1, // 現在のページ
            yosanTotalpage: 0,   // ページの全数
            kouhouCurrentpage: 1, // 現在のページ
            kouhouTotalpage: 0,   // ページの全数
            gikaigijirokuCurrentpage: 1, // 現在のページ
            gikaigijirokuTotalpage: 0,   // ページの全数
            sonotaCurrentpage: 1, // 現在のページ
            sonotaTotalpage: 0,   // ページの全数
            pageWidth: 111,  // 1ページの幅
            itemsGroup: 6,
            keikakuhoshinCurrentGroupIndex: 0,
            keikakuhoshinGroups: [],
            yosanCurrentGroupIndex: 0,
            yosanGroups: [],
            kouhouCurrentGroupIndex: 0,
            kouhouGroups: [],
            gikaigijirokuCurrentGroupIndex: 0,
            gikaigijirokuGroups: [],
            sonotaCurrentGroupIndex: 0,
            sonotaGroups: [],
            affiliations: affiliationsData,
            organizations: organizationsData,
        }
    },
    computed: {
        stylePaddingTop(){
            return this.$store.getters.getHeaderHeight + 30 + "px";
        },
        getKeikakuhoshin(){
            this.resetKeikakuhoshinCurrentPage();
            return this.$store.getters.getDocumentKeikakuhoshin;
        },
        getYosan(){
            this.resetYosanCurrentPage();
            return this.$store.getters.getDocumentYosan;
        },
        getKouhou(){
            this.resetKouhouCurrentPage();
            return this.$store.getters.getDocumentKouhou;
        },
        getGikaigijiroku(){
            this.resetGikaigijirokuCurrentPage();
            return this.$store.getters.getDocumentGikaigijiroku;
        },
        getSonota(){
            this.resetSonotaCurrentPage();
            return this.$store.getters.getDocumentSonota;
        },

        //Keikakuhoshin配列を2つずつ組み合わせてループ
        groupedKeikakuhoshin() {
            let result = [];
            let itemsPerGroup = 2;
            for (let i = 0; i < this.getKeikakuhoshin.length; i += itemsPerGroup) {
                result.push(this.getKeikakuhoshin.slice(i, i + itemsPerGroup));
            }
            // console.log("resultlength:",result.length);
            return result;
        },
        //Yosan配列を2つずつ組み合わせてループ
        groupedYosan() {
            let result = [];
            let itemsPerGroup = 2;
            for (let i = 0; i < this.getYosan.length; i += itemsPerGroup) {
                result.push(this.getYosan.slice(i, i + itemsPerGroup));
            }
            // console.log("resultlength:",result.length);
            return result;
        },
        //kouhou配列を2つずつ組み合わせてループ
        groupedKouhou() {
            let result = [];
            let itemsPerGroup = 2;
            for (let i = 0; i < this.getKouhou.length; i += itemsPerGroup) {
                result.push(this.getKouhou.slice(i, i + itemsPerGroup));
            }
            
            return result;
        },
        //gikaigijiroku配列を2つずつ組み合わせてループ
        groupedGikaigijiroku() {
            let result = [];
            let itemsPerGroup = 2;
            for (let i = 0; i < this.getGikaigijiroku.length; i += itemsPerGroup) {
                result.push(this.getGikaigijiroku.slice(i, i + itemsPerGroup));
            }
            
            return result;
        },
        //sonota配列を2つずつ組み合わせてループ
        groupedSonota() {
            let result = [];
            let itemsPerGroup = 2;
            for (let i = 0; i < this.getSonota.length; i += itemsPerGroup) {
                result.push(this.getSonota.slice(i, i + itemsPerGroup));
            }
            
            return result;
        },

        keikakuhoshinPages() {
            return Math.ceil(this.getKeikakuhoshin.length / 6); // 6枚ずつまとめてページ数を計算
        },
        yosanPages() {
            return Math.ceil(this.getYosan.length / 6); // 6枚ずつまとめてページ数を計算
        },
        kouhouPages() {
            return Math.ceil(this.getKouhou.length / 6); // 6枚ずつまとめてページ数を計算
        },
        gikaigijirokuPages() {
            return Math.ceil(this.getGikaigijiroku.length / 6); // 6枚ずつまとめてページ数を計算
        },
        sonotaPages() {
            return Math.ceil(this.getSonota.length / 6); // 6枚ずつまとめてページ数を計算
        },
        
        // keikakuhoshin現在のpositionからleftに変換
        keikakuhoshinCurrentLeft() {
            console.log(this.keikakuhoshinPageToPosition(),"rem");
            return String(this.keikakuhoshinPageToPosition()) + "rem";
        },
        // yosan現在のpositionからleftに変換
        yosanCurrentLeft() {
            return String(this.yosanPageToPosition()) + "rem";
        },
        // kouhou現在のpositionからleftに変換
        kouhouCurrentLeft() {
            return String(this.kouhouPageToPosition()) + "rem";
        },
        // gikaigijiroku現在のpositionからleftに変換
        gikaigijirokuCurrentLeft() {
            return String(this.gikaigijirokuPageToPosition()) + "rem";
        },
        // sonota現在のpositionからleftに変換
        sonotaCurrentLeft() {
            return String(this.sonotaPageToPosition()) + "rem";
        },
            
        currentGroupkeikakuhoshin() {
            return this.keikakuhoshinGroups[this.keikakuhoshinCurrentGroupIndex];
        },
        currentGroupYosan() {
            return this.yosanGroups[this.yosanCurrentGroupIndex];
        },
        currentGroupKouhou() {
            return this.kouhouGroups[this.kouhouCurrentGroupIndex];
        },
        currentGroupGikaigijiroku() {
            return this.gikaigijirokuGroups[this.gikaigijirokuCurrentGroupIndex];
        },
        currentGroupSonota() {
            return this.sonotaGroups[this.sonotaCurrentGroupIndex];
        },
  },
  methods: {
    // ページを1つ進める
    keikakuhoshinForwardPage() {
      // 最後のページの場合return
      if (this.keikakuhoshinCurrentpage === this.keikakuhoshinTotalpage) {
        return;
      }
      this.keikakuhoshinCurrentpage += 1;


      if (this.keikakuhoshinCurrentGroupIndex < this.keikakuhoshinGroups.length - 1) {
        this.keikakuhoshinCurrentGroupIndex++;
      }
    },
    // ページを1つ戻す
    keikakuhoshinBackwardPage() {
      // 最初のページの場合return
      if (this.keikakuhoshinCurrentpage === 1) {
        return;
      }
      this.keikakuhoshinCurrentpage -= 1;

      if (this.keikakuhoshinCurrentGroupIndex > 0) {
        this.keikakuhoshinCurrentGroupIndex--;
      }
    },
    // 現在のページをpositionに変換
    keikakuhoshinPageToPosition() {
      console.log("positionPage:",this.keikakuhoshinCurrentpage - 1);
      console.log("posotionPageWidth:", -this.pageWidth);
      return -this.pageWidth * (this.keikakuhoshinCurrentpage - 1);
    },

    // ページを1つ進める
    yosanForwardPage() {
    // 最後のページの場合return
      if (this.yosanCurrentpage === this.yosanTotalpage) {
        return;
      }
      this.yosanCurrentpage += 1;

      if (this.yosanCurrentGroupIndex < this.yosanGroups.length - 1) {
        this.yosanCurrentGroupIndex++;
      }
    },
    // ページを1つ戻す
    yosanBackwardPage() {
    // 最初のページの場合return
      if (this.yosanCurrentpage === 1) {
        return;
      }
      this.yosanCurrentpage -= 1;

      if (this.yosanCurrentGroupIndex > 0) {
        this.yosanCurrentGroupIndex--;
      }
    },
    // 現在のページをpositionに変換
    yosanPageToPosition() {
      return -this.pageWidth * (this.yosanCurrentpage - 1);
    },

    // ページを1つ進める
    kouhouForwardPage() {
    // 最後のページの場合return
      if (this.kouhouCurrentpage === this.kouhouTotalpage) {
        return;
      }
      this.kouhouCurrentpage += 1;

      if (this.kouhouCurrentGroupIndex < this.kouhouGroups.length - 1) {
        this.kouhouCurrentGroupIndex++;
      }
    },
    // ページを1つ戻す
    kouhouBackwardPage() {
    // 最初のページの場合return
      if (this.kouhouCurrentpage === 1) {
        return;
      }
      this.kouhouCurrentpage -= 1;
      
      if (this.kouhouCurrentGroupIndex > 0) {
        this.kouhouCurrentGroupIndex--;
      }
    },
    // 現在のページをpositionに変換
    kouhouPageToPosition() {
      return -this.pageWidth * (this.kouhouCurrentpage - 1);
    },

    // ページを1つ進める
    gikaigijirokuForwardPage() {
    // 最後のページの場合return
      if (this.gikaigijirokuCurrentpage === this.gikaigijirokuTotalpage) {
        return;
      }
      this.gikaigijirokuCurrentpage += 1;

      if (this.gikaigijirokuCurrentGroupIndex < this.gikaigijirokuGroups.length - 1) {
        this.gikaigijirokuCurrentGroupIndex++;
      }
    },
    // ページを1つ戻す
    gikaigijirokuBackwardPage() {
    // 最初のページの場合return
      if (this.gikaigijirokuCurrentpage === 1) {
        return;
      }
      this.gikaigijirokuCurrentpage -= 1;

      if (this.gikaigijirokuCurrentGroupIndex > 0) {
        this.gikaigijirokuCurrentGroupIndex--;
      }
    },
    // 現在のページをpositionに変換
    gikaigijirokuPageToPosition() {
      return -this.pageWidth * (this.gikaigijirokuCurrentpage - 1);
    },

    // ページを1つ進める
    sonotaForwardPage() {
    // 最後のページの場合return
      if (this.sonotaCurrentpage === this.sonotaTotalpage) {
        return;
      }
      this.sonotaCurrentpage += 1;

      if (this.sonotaCurrentGroupIndex < this.sonotaGroups.length - 1) {
        this.sonotaCurrentGroupIndex++;
      }
    },
    // ページを1つ戻す
    sonotaBackwardPage() {
    // 最初のページの場合return
      if (this.sonotaCurrentpage === 1) {
        return;
      }
      this.sonotaCurrentpage -= 1;

      if (this.sonotaCurrentGroupIndex > 0) {
        this.sonotaCurrentGroupIndex--;
      }
    },
    // 現在のページをpositionに変換
    sonotaPageToPosition() {
      return -this.pageWidth * (this.sonotaCurrentpage - 1);
    },

    keikakuhoshinCalculateGroups() {
      this.keikakuhoshinGroups = [];
      for (let i = 0; i < this.getKeikakuhoshin.length; i += this.itemsGroup) {
        const start = i + 1;
        const end = Math.min(i + this.itemsGroup, this.getKeikakuhoshin.length);
        this.keikakuhoshinGroups.push({ start, end });
      }
    },
    yosanCalculateGroups() {
      this.yosanGroups = [];
      for (let i = 0; i < this.getYosan.length; i += this.itemsGroup) {
        const start = i + 1;
        const end = Math.min(i + this.itemsGroup, this.getYosan.length);
        this.yosanGroups.push({ start, end });
      }
    },
    kouhouCalculateGroups() {
      this.kouhouGroups = [];
      for (let i = 0; i < this.getKouhou.length; i += this.itemsGroup) {
        const start = i + 1;
        const end = Math.min(i + this.itemsGroup, this.getKouhou.length);
        this.kouhouGroups.push({ start, end });
      }
    },
    gikaigijirokuCalculateGroups() {
      this.gikaigijirokuGroups = [];
      for (let i = 0; i < this.getGikaigijiroku.length; i += this.itemsGroup) {
        const start = i + 1;
        const end = Math.min(i + this.itemsGroup, this.getGikaigijiroku.length);
        this.gikaigijirokuGroups.push({ start, end });
      }
    },
    sonotaCalculateGroups() {
      this.sonotaGroups = [];
      for (let i = 0; i < this.getSonota.length; i += this.itemsGroup) {
        const start = i + 1;
        const end = Math.min(i + this.itemsGroup, this.getSonota.length);
        this.sonotaGroups.push({ start, end });
      }
    },
    findAffiliation(affiliationCode) {
      const foundAffiliation = this.affiliations.find(
        affiliation => affiliation.affiliation_code === affiliationCode
      );
      //該当するデータがあれば、そのデータのname値を返し、なければ空文字を返します。
      return foundAffiliation ? foundAffiliation.affiliation : "";
    },
    findOrganization(organizationCode) {
      const foundOrganization = this.organizations.find(
        organization => organization.code === organizationCode
      );
      //該当するデータがあれば、そのデータのname値を返し、なければ空文字を返します。
      return foundOrganization ? foundOrganization.name : "";
    },
    //文字制限
    getTitleLength(title) {
        let len = 0;
        for (let i = 0; i < title.length; i++) {
            (title[i].match(/[ -~]/)) ? len += 1 : len += 2;
        }
        return len;
    },
    limitString(title, limit) {
        if (this.getTitleLength(title) > limit) {
            return title.slice(0, Math.floor(limit / 2)) + '...';
        }
        return title;
    },
    shouldIncreaseHeight(title) {
        // titleの長さによってheightを調節するには、必要な条件を定義します。
        return this.getTitleLength(title) <= 33;
    },
    subCategoryReformat(raw_sub_category){
        /**
         * raw_sub_category: int
         * document_data_format: json
         */
        if (
            raw_sub_category === undefined ||
            raw_sub_category === null ||
            document_data_format.sub_category[raw_sub_category] === undefined || 
            document_data_format.sub_category[raw_sub_category] === null
        ){
            return " - ";
        } else {
            return document_data_format.sub_category[raw_sub_category];
        }
    },
    getFiscalYear(document_data){
        let fiscal_year_start = "-";
        let fiscal_year_end = "-";
        if(document_data.fiscal_year_start !== undefined || document_data.fiscal_year_start !== null){
            fiscal_year_start = document_data.fiscal_year_start;
        }
        if(document_data.fiscal_year_end !== undefined || document_data.fiscal_year_end !== null){
            fiscal_year_end = document_data.fiscal_year_end;
        }
        if(fiscal_year_start == fiscal_year_end){ return fiscal_year_start; }
        return fiscal_year_start + "-" + fiscal_year_end;
    },
    getSourceUrl(document_data){
        let url = "";
        let page_number_slug = "";
        if(document_data.file_page !== undefined || document_data.file_page !== null){
            page_number_slug = "#page=" + document_data.file_page;
        }
        // if(document_data.source_url_is_alive === false){
        //     url = process.env.VUE_APP_DOCUMENT_PAGE_CAPTCHA_IMAGE_URL + document_data.file_id + ".pdf";
        //     return url + page_number_slug;
        // }
        url = document_data.source_url;
        return url + page_number_slug;
    },
    getOwnedFileUrl(document_data){
        let url = "";
        let page_number_slug = "";
        if(document_data.file_page !== undefined || document_data.file_page !== null){
            page_number_slug = "#page=" + document_data.file_page;
        }
        url = process.env.VUE_APP_DOCUMENT_PAGE_CAPTCHA_IMAGE_URL + document_data.file_id + ".pdf";
        return url + page_number_slug;
    },
    getImageUrl(document_data){
        const image_url = process.env.VUE_APP_DOCUMENT_PAGE_CAPTCHA_IMAGE_URL + document_data.file_id + "/" + document_data.file_page + ".jpg";
        return image_url;
    },
    getContentText(document_data){
        if(document_data.highlight !== undefined
            && document_data.highlight !== null 
            && "content_text" in document_data.highlight
            && Array.isArray(document_data.highlight.content_text)
            && document_data.highlight.content_text.length > 0){
            return document_data.highlight.content_text.join("... ");
        }
        if(document_data.content_text === undefined || document_data.content_text === null){
                return "";
        } else {
            return document_data.content_text;
        }
    },
    resetKeikakuhoshinCurrentPage(){
        this.keikakuhoshinCurrentpage = 1;
    },
    resetYosanCurrentPage(){
        this.yosanCurrentpage = 1;
    },
    resetKouhouCurrentPage(){
        this.kouhouCurrentpage = 1;
    },
    resetGikaigijirokuCurrentPage(){
        this.gikaigijirokuCurrentpage = 1;
    },
    resetSonotaCurrentPage(){
        this.sonotaCurrentpage = 1;
    },
    select_content(content, row, column){
        let row_column = {"row": row + 1, "column": column + 1};
        let google_analytics_logger = new LogEventSelectContent(
            content.file_id,
            content.file_page,
            content.category,
            content.sub_category,
            content.title,
            content.code,
            row_column,
        );
        try {
            google_analytics_logger.log_event();
            console.log("DocumentSection.select_content", content, row_column);
        } catch (error) {
            console.log("DocumentSection.select_content", error);
        }
    },
  },

  watch: {
    // 配列が変更されるたびにページ数更新
    getKeikakuhoshin: {
        handler() {
        this.keikakuhoshinTotalpage = this.keikakuhoshinPages;

        this.keikakuhoshinCalculateGroups();
        this.keikakuhoshinCurrentGroupIndex = 0;
        },
        immediate: true, // 初期化時点でもwatchハンドラーを実行
    },

    // 配列が変更されるたびにページ数更新
    getYosan: {
        handler() {
        this.yosanTotalpage = this.yosanPages;

        this.yosanCalculateGroups();
        this.yosanCurrentGroupIndex = 0;
        },
        immediate: true, //  初期化時点でもwatchハンドラーを実行
    },

    // 配列が変更されるたびにページ数更新
    getKouhou: {
        handler() {
        this.kouhouTotalpage = this.kouhouPages;

        this.kouhouCalculateGroups();
        this.kouhouCurrentGroupIndex = 0;
        },
        immediate: true, //  初期化時点でもwatchハンドラーを実行
    },

    // 配列が変更されるたびにページ数更新
    getGikaigijiroku: {
        handler() {
        this.gikaigijirokuTotalpage = this.gikaigijirokuPages;

        this.gikaigijirokuCalculateGroups();
        this.gikaigijirokuCurrentGroupIndex = 0;
        },
        immediate: true, //  初期化時点でもwatchハンドラーを実行
    },

    // 配列が変更されるたびにページ数更新
    getSonota: {
        handler() {
        this.sonotaTotalpage = this.sonotaPages;

        this.sonotaCalculateGroups();
        this.sonotaCurrentGroupIndex = 0;
        },
        immediate: true, //  初期化時点でもwatchハンドラーを実行
    },
  },
}
</script>

<style scoped>
.cards {
  overflow: hidden;
}

.card_item_track{
  background: #F1F1F1;
  padding: 2rem 0 0;
  display: flex;
  transition: left 0.5s ease;
  position: relative;
  left: 0;
  width: 30000px;
}

.group_card_item{
}

.card_item {
  padding: 10px;
  border: 1px solid #ccc;
}

</style>

<style>
.v-carousel__controls{
    position: absolute; 
    top: 614px; 
    left: auto; 
    right: auto;
}
</style>